<template>
  <div class="join my-page-wrap">
    <div class="inner">
      <div class="content">
        <div class="left">
          <img src="~@/assets/imgs/left.png" alt="" />
        </div>
        <div class="center main-content">
          <div class="title_require_img"></div>
          <div class="require_text">
            <div class="text tajust" v-html="detail.content"></div>
            <!-- <div>1、具有合法的经营资质和经营场所，能够遵守国家相关法律法规和行业规范;</div>
            <div>2、具有一定的市场开发能力和销售能力，能够积极推广和销售我们的产品;</div>
            <div>3、具有良好的商业信誉和品牌意识，能够维护我们燕台山品牌的形象和声誉;</div>
            <div>4、具有一定的资金实力和管理能力，能够按照我们的要求进行店面装修和运营管理;</div>
            <div>5、具有一定的行业经验和相关知识，能够理解和掌握我们的产品特点和销售技巧。</div>
            <div style="padding: 12px 0">如果您满足以上要求，并且对我们的品牌和产品有兴趣，欢迎联系我们进行加盟合作。<br />我们将为您提供全方位的支持和帮助，共同发展壮大。</div> -->
          </div>
          <div class="title_join_img"></div>
          <div class="form">
            <el-form :model="formData" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
              <el-form-item label="姓名" prop="name">
                <el-input v-model="formData.name" placeholder="请输入姓名"></el-input>
              </el-form-item>

              <el-row>
                <el-col :span="12">
                  <el-form-item label="手机号" prop="tel">
                    <el-input v-model="formData.tel" placeholder="请输入手机号码"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="地区" prop="area">
                    <el-input v-model="formData.area" placeholder="请输入地区"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>

              <el-form-item label="备注" prop="remark">
                <el-input v-model="formData.remark" type="textarea" :rows="3" placeholder="请输入备注"></el-input>
              </el-form-item>

              <el-form-item>
                <el-button class="btn" @click="submitForm">提交</el-button>
              </el-form-item>
            </el-form>
          </div>

          <!-- 销售网点 -->
          <div class="node">
            <div class="join_tit">
              <a @click="$router.push('/net')">更多网点>>></a>
            </div>
            <NodeList2 />
          </div>
        </div>
        <div class="right">
          <img src="~@/assets/imgs/right.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import http from "@/utils/http"
  import NodeList2 from "./components/NodeList2.vue"
export default {
  components: {
      NodeList2
    },
    data() {
      return {
        formData: {
          name: "",
          tel: "",
          area: "",
          remark: ""
        },
        isSubmit:false,
        detail: {},

        rules: {
          name: [
            { required: true, message: "请输入姓名", trigger: "blur" },
            { min: 2, max: 8, message: "姓名长度范围在 2 到 8 个字符", trigger: "blur" }
          ],
          tel: [
            { required: true, message: "请输入手机号", trigger: "blur" },
            { min: 6, max: 11, message: "号码长度范围在 6 到 11 个字符", trigger: "blur" }
          ],
          area: [
            { required: true, message: "请输入地址", trigger: "blur" },
            { min: 5, max: 50, message: "地址长度范围在 5 到 50 个字符", trigger: "blur" }
          ],
          remark: [
            { required: true, message: "请输入备注", trigger: "blur" },
            { min: 0, max: 150, message: "备注最大长度是 150 个字符", trigger: "blur" }
          ]
        }
      }
    },
    mounted() {
      this.getDetail()
    },

    methods: {
      getDetail(id) {
        http({
          url: "/api/help/info?id=5",
          data: {}
        }).then((res) => {
          this.detail = res.data
        })
      },
      submitForm() {
        if(this.isSubmit){
          this.$message({ message: "已提交，请勿重复提交！", type: "info" });
          return;
        }
        // 使用 Promise
        // 对整个表单进行校验，返回一个 Promise
        this.$refs.ruleForm
          .validate()
          .then((res) => {
            // 成功返回，res 为表单数据
            // 其他逻辑处理
            // ...
            this.formHandler()
          })
          .catch((err) => {
            // 表单校验验失败，err 为具体错误信息
            // 其他逻辑处理
            // ...
            this.$message({ message: "请完善信息", type: "info" })
          })
      },

      formHandler() {
        http({
          url: "/api/jiameng/applyjiameng",
          data: this.formData
        }).then((res) => {
          if (res.code === 0) {
            this.$message({ message: res.message, type: "success" })
            this.isSubmit=true;
            setTimeout(() => {
              // this.$router.back()
              // location.reload()
            }, 1000)
          }
        })
      }
    }
  }
</script>

<style lang="scss">
$form_input_fs: 18px;
  .join {
    .center {
      // margin-top: 46px;
      .title_require_img {
        width: 153px;
        height: 31.5px;
        background: url(../../assets/imgs/join_require.png) center no-repeat;
        background-size: cover;
      }
      .require_text {
        margin-top: 20px;
        text-align: left;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 25px;
      }
      .title_join_img {
        margin-top: 47px;
        width: 153px;
        height: 31.5px;
        background: url(../../assets/imgs/join_join.png) center no-repeat;
        background-size: cover;
      }
    }
    .form {
      background: #f5f5f5;
      border-radius: 8px;
      margin-top: 20px;
      padding: 45px 100px;

      .el-form {
        width: 100%;
        .el-form-item__label{
          color:#333;
          font-size:$form_input_fs;
        }
        .el-input__inner,.el-textarea__inner{
          font-size:$form_input_fs;
          color:#333;
          font-family:inherit;
          &::-webkit-input-placeholder{
            color:#666;
            font-family:inherit;
          }
        }
      }
    }

    .btn.el-button {
      margin-top: 28px;
      width: 108px;
      height: 30px;
      transform: scale(1.2);
      padding: 0;
      background: #a20000;
      color: white;
      border-radius: 7px;
      font-size: 16px;
      border: none;
    }
  }
  .node{
    padding-bottom:50px;
  }
  .join_tit{
    margin-top: 47px;
    width: 100%;
    height: 31.5px;
    background: url(../../assets/imgs/net_tit.png)  no-repeat left center;
    background-size: 153px 31.5px;
    margin-bottom:15px;
  }
  .join_tit a{
    float:right;
    color:#b50003;
    cursor: pointer;
    font-weight: bold;
  }
  .join_tit a:hover{
    font-size: large;
  }
</style>
