<template>
  <div class="node-list">
    <div class="card" v-for="item in list.slice(0, 4)" @click="toDetail(item)" :key="item.store_id">
      <img class="store-img" :src="$baseRemote + item.store_image" alt="" />

      <div class="bottom">
        <h3>{{ item.store_name }}</h3>

        <p>地址：{{ item.full_address }} {{ item.address }}</p>
        <p>电话：{{ item.telphone }}</p>
        <p>传真：{{ item.telphone }}</p>

        <!--  <div class="show-map" @click="jumpMap(item)">
          <span>查看地址</span>
          <img src="~@/assets/imgs/address.png" alt="" />
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
  import http from "@/utils/http"

  export default {
    data() {
      return {
        list: []
      }
    },

    mounted() {
      this.getList()
    },

    methods: {
      getList() {
        http({
          url: "/api/store/page",
          data: {}
        }).then((res) => {
          console.log(res, 11111)
          this.list = res.data.list
        })
      },

      // https://ditu.baidu.com/search/?querytype=s&wd=

      jumpMap(item) {
        // location.href = "https://ditu.baidu.com/search/?querytype=s&wd=" + item.full_address + item.address
        window.open("https://ditu.baidu.com/search/?querytype=s&wd=" + item.full_address + item.address)
      },
      toDetail(item) {
        this.$router.push({ path: "/netdetail?id=" + item.store_id })
      }
    }
  }
</script>

<style lang="scss" scoped>
  .node-list {
    display: flex;
    .card {
      text-align: left;
      width: 340px;
      cursor: pointer;
      margin-right: 12px;
      border-top-right-radius: 25px;

      .bottom {
        padding: 14px 18px;
        min-height: 190px;
        background-color: #eeeef7;
      }
      .store-img {
        height: 240px;
        width: 100%;
        border-top-right-radius: 25px;
      }

      h3 {
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #2f2f31;
        line-height: 19px;
        font-weight: bold;
      }

      p {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #525255;
        margin-top: 10px;
      }
    }
    .show-map {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      color: #2f8cec;
      margin-top: 16px;
      cursor: pointer;
      img {
        width: 13px;
        height: 13px;
      }
    }
  }
</style>
